import React, { Component } from 'react'
import { connect } from 'react-redux'
import FramePesquisa from './frame_pesquisa'
import FormCliente from './form_cliente'

class FrameCliente extends Component {
  constructor(props) {
    super(props)
    this.state = { openDialog: false }
  }

  handleOpenDialog = () => {
    this.setState({ openDialog: true })
  }

  handleCloseDialog = () => {
    this.setState({ openDialog: false })
  }

  filtrarCampoEspecial = (dado, valorInput) => {
    return dado.cpfCnpj?.includes(
      valorInput.replace('.', '').replace('-', '').replace('/', '')
    )
  }

  render() {
    return (
      <React.Fragment>
        <FramePesquisa
          action={this.props.selecionarCliente}
          dados={this.props.clientes}
          desabilitado={this.props.desabilitado}
          tamanho={3}
          campos={['abrev', 'nome', 'codAlt', 'cpfCnpjFormatado']}
          filtrarCampoEspecial={this.filtrarCampoEspecial}
          placeholder="Selecione um cliente"
          handleOpenDialog={this.handleOpenDialog}
          {...this.props}
        />
        {this.state.openDialog && (
          <FormCliente
            openDialog={this.state.openDialog}
            id={this.props.default.id}
            handleClose={this.handleCloseDialog}
          />
        )}
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    clientes: state.clientes,
  }
}

export default connect(mapStateToProps, null)(FrameCliente)
