import React, { Component } from 'react'
import Autosuggest from 'react-autosuggest'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import Backspace from '@material-ui/icons/Backspace'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import InputAdornment from '@material-ui/core/InputAdornment'
import Description from '@material-ui/icons/Description'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'

class FramePesquisa extends Component {
  static defaultProps = {
    limite: 20,
    desabilitado: false,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (_.isEmpty(nextProps.campos) && prevState.value !== '') {
      return { value: '' }
    }
    if (_.isEmpty(nextProps.default) && prevState.value !== '') {
      if (prevState.selecionado) {
        return { value: '', selecionado: false }
      }
    }
    if (!_.isEmpty(nextProps.default)) {
      const value = FramePesquisa.obterDado(nextProps.default, nextProps.campos)
      if (value !== prevState.value) {
        return { value, selecionado: value !== '' }
      }
    }

    return {}
  }

  static obterDado(dado, campos) {
    var resultado = ''
    if (campos.length === 1) {
      const info = dado[campos[0]]
      // Há campos que podem estar null, nesse caso irá considerar vazio('')
      return _.isEmpty(info) ? '' : info // Um campo só
    }

    for (var i = 0; i < campos.length; i++) {
      const info = dado[campos[i]]
      // Há campos que podem estar null, nesse caso irá considerar vazio('')
      const v = resultado + (_.isEmpty(info) ? '' : info)
      resultado = i === 0 ? `( ${v} )` : v // Primeiro campo ficará entre parenteses
      if (i + 1 !== campos.length) {
        resultado = resultado + ' - ' // Ultimo campo
      }
    }
    return resultado
  }

  constructor(props) {
    super(props)
    this.state = { suggestions: [], value: '', selecionado: false }
    this.handleLimpar = this.handleLimpar.bind(this)
  }

  handleLimpar() {
    this.handleChange(null, { newValue: '' })
    this.setState({ selecionado: false })
  }

  getActionsInput = () => {
    const adornments = []
    if (this.props.handleOpenDialog) {
      const adornment = (
        <InputAdornment key={_.uniqueId()} position="end">
          <IconButton
            style={{ padding: 2 }}
            onClick={() => this.props.handleOpenDialog()}
            disabled={!this.state.selecionado}
          >
            <Description fontSize="small" />
          </IconButton>
        </InputAdornment>
      )
      adornments.push(adornment)
    }
    const adornment = (
      <InputAdornment key={_.uniqueId()} position="end">
        <IconButton
          style={{ padding: 2, marginRight: 0 }}
          onClick={this.handleLimpar}
        >
          <Backspace fontSize="small" color="action" />
        </IconButton>
      </InputAdornment>
    )
    adornments.push(adornment)
    return adornments
  }

  renderInput(inputProps) {
    const { classes, autoFocus, value, ref, ...other } = inputProps
    return (
      <div style={{ display: 'flex' }}>
        <TextField
          variant="outlined"
          disabled={_.isEmpty(this.props.dados) || this.props.desabilitado}
          autoFocus={autoFocus}
          className={classes.textField}
          value={value}
          inputRef={ref}
          InputProps={{
            classes: {
              input: classes.textFieldInput,
            },
            ...other,
            endAdornment: this.getActionsInput(),
          }}
        ></TextField>
      </div>
    )
  }

  getSuggestions(value) {
    const inputValue = _.deburr(value.trim().toLowerCase())
    const inputLength = inputValue.length
    let sugestoes
    if (inputLength >= this.props.tamanho) {
      if (this.props.filtrarCampoEspecial) {
        sugestoes = this.props.dados.filter((dado) => {
          return (
            FramePesquisa.obterDado(dado, this.props.campos)
              .toLowerCase()
              .includes(inputValue) ||
            this.props.filtrarCampoEspecial(dado, inputValue)
          )
        })
      } else {
        sugestoes = this.props.dados.filter((dado) => {
          return FramePesquisa.obterDado(dado, this.props.campos)
            .toLowerCase()
            .includes(inputValue)
        })
      }
      if (sugestoes.length >= this.props.limite) {
        // Ordena pelo campo principal dando preferencia para o de menor tamanho
        sugestoes.sort((a, b) => {
          // Há campos que podem estar null, nesse caso irá considerar vazio('')
          var v1 = a[this.props.campos[0]]
          v1 = _.isEmpty(v1) ? '' : v1
          var v2 = b[this.props.campos[0]]
          v2 = _.isEmpty(v2) ? '' : v2
          return v1.length - v2.length
        })
        return sugestoes.slice(0, this.props.limite - 1)
      } else {
        return sugestoes
      }
    } else {
      return []
    }
  }

  getSuggestionValue(suggestion) {
    return FramePesquisa.obterDado(suggestion, this.props.campos)
  }

  renderSuggestion(suggestion, { query, isHighlighted }) {
    const dado = FramePesquisa.obterDado(suggestion, this.props.campos)
    const matches = match(dado, query)
    const parts = parse(dado, matches)
    return (
      <MenuItem selected={isHighlighted} component="div">
        <div>
          {parts.map((part, index) => {
            return part.highlight ? (
              <span key={index} style={{ fontWeight: 300, color: 'red' }}>
                {part.text}
              </span>
            ) : (
              <strong key={index} style={{ fontWeight: 500 }}>
                {part.text}
              </strong>
            )
          })}
        </div>
      </MenuItem>
    )
  }

  renderSuggestionsContainer(options) {
    const { containerProps, children } = options

    return (
      <Paper {...containerProps} square>
        {children}
      </Paper>
    )
  }

  handleSuggestionsFetchRequested({ value }) {
    this.setState({ suggestions: this.getSuggestions(value) })
  }

  handleSuggestionsClearRequested(value) {
    this.setState({ suggestions: [] })
  }

  handleChange(event, { newValue }) {
    if (newValue === '') {
      this.props.action({})
    }

    this.setState({ value: newValue })
  }

  onSuggestionSelected(event, { suggestion }) {
    this.setState({ selecionado: true })
    this.props.action(suggestion)
  }

  render() {
    const { classes } = this.props

    return (
      <Autosuggest
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
        }}
        focusInputOnSuggestionClick={false}
        renderInputComponent={this.renderInput.bind(this)}
        suggestions={this.state.suggestions}
        onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested.bind(
          this
        )}
        onSuggestionsClearRequested={this.handleSuggestionsClearRequested.bind(
          this
        )}
        renderSuggestionsContainer={this.renderSuggestionsContainer.bind(this)}
        getSuggestionValue={this.getSuggestionValue.bind(this)}
        renderSuggestion={this.renderSuggestion.bind(this)}
        onSuggestionSelected={this.onSuggestionSelected.bind(this)}
        inputProps={{
          autoFocus: false,
          classes,
          placeholder: this.props.placeholder,
          value: this.state.value,
          onChange: this.handleChange.bind(this),
        }}
      />
    )
  }
}

const styles = (theme) => ({
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 2,
    marginTop: theme.spacing(),
    left: 0,
    right: 0,
    overflowY: 'auto',
    maxHeight: 200,
  },

  suggestion: {
    cursor: 'pointer',
    '& > div': {
      fontSize: 10,
      paddingTop: 5,
      paddingBottom: 5,
    },
  },

  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },

  textField: {
    width: '100%',
  },

  textFieldInput: {
    fontSize: 12,
  },

  textFieldFormLabel: {
    fontSize: 12,
  },
})

export default withStyles(styles)(FramePesquisa)
