import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import axios from 'axios';
import APIUtils from '../utils/api_util';
import { CircularProgress } from '@material-ui/core';

const url = `${APIUtils.API_URL}/api/v1/cliente`;
const url2 = `${APIUtils.API_URL}/api/v1/tabelas/cidade`;
const url3 = `${APIUtils.API_URL}/api/v1/cliente/contato`;

function PaperComponent(props) {
  return <Paper style={{ margin: 5 }} {...props} />;
}

const FormCliente = (props) => {
  const [cliente, setCliente] = useState({});
  const [cidade, setCidade] = useState({});
  const [nomesContato, setNomesContato] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCliente = async () => {
      setLoading(true);
      const response = await axios.get(`${url}/${props.id}`);
      const response2 = await axios.get(`${url2}/${response.data.idCidade}`);
      const response3 = await axios.get(`${url3}/${props.id}`);
      setCliente(response.data);
      setCidade(response2.data);
      setNomesContato(response3.data);
      setLoading(false);
    };
    fetchCliente();
  }, [props.id]);

  const handleClose = () => {
    props.handleClose();
  };

  return (
    <div>
      <Dialog
        open={props.openDialog}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle id="draggable-dialog-title">
          Cadastro do Cliente
        </DialogTitle>
        <DialogContent
          style={{ padding: 10, display: 'flex', flexDirection: 'column' }}
        >
          <TextField
            id="standard-name"
            disabled
            style={{ margin: 5 }}
            label="Nome:"
            value={cliente.nome || ''}
          />
          <div style={{ display: 'flex' }}>
            <TextField
              style={{ flex: 2, margin: 5 }}
              disabled
              label="Endereço:"
              value={cliente.lograd || ''}
            />
            <TextField
              style={{ flex: 1, margin: 5 }}
              disabled
              label="Nro:"
              value={cliente.nro || ''}
            />
          </div>
          <div style={{ display: 'flex' }}>
            <TextField
              style={{ flex: 2, margin: 5 }}
              disabled
              label="Bairro:"
              value={cliente.bairro || ''}
            />
            <TextField
              style={{ flex: 1, margin: 5 }}
              disabled
              label="CEP:"
              value={cliente.cep || ''}
            />
          </div>
          <TextField
            style={{ margin: 5 }}
            disabled
            label="E-mail:"
            value={cliente.email || ''}
          />
          <div style={{ margin: 5 }}>
            <Typography inline={true}> Fone: </Typography>
            <Link href={`tel:+55${cidade.ddd}${cliente.fone}`} underline="none">
              {`(${cidade.ddd}) ${cliente.fone}`}
            </Link>{' '}
          </div>
          <div style={{ margin: 5 }}>
            <Typography inline={true} style={{ marginTop: 5 }}>
              Contato:{' '}
            </Typography>
            {loading ? (
              <div style={{ width: '100%', textAlign: 'center' }}>
                <CircularProgress thickness={2} size={20} />
              </div>
            ) : (
              nomesContato.join(', ') || 'Nenhum nome de contato salvo.'
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default FormCliente;
